import React from 'react';
import {Link} from "gatsby";

function ServiceItem(props) {
    const serviceURL = `/service/${props.title.split(' ').join('-').toLowerCase()}?id=${props.id}`
    return (
        <>
        <div className="col-sm-6 col-lg-4">
            <div>

                   
                        <img src={require('../../assets/img/' + props.thumb)} alt={props.title}/>


     

                <div className="service-content" style={{backgroundColor:'#ff9026', padding:'10% 2%'}}>
                    <div className="service-content-inner" >
                        <h5 style={{color:'black',fontWeight:'500', textAlign:'center'}}>
                            {props.title}
                        </h5>
                        <p style={{color:'black', textAlign:'center'}}>{props.text}</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default ServiceItem;