import React from 'react';
import {Link} from 'gatsby'
import SlickSlider from '../../components/UI/Slick/index'

const NextArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-right" style={{color:'#ff9026'}}/></button>
    )
};

const PrevArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-left" style={{color:'#ff9026'}}/></button>
    )
};

function BlogItem(props) {
    const blogURL = `/blog/${props.title.split(' ').join('-').toLocaleLowerCase()}?id=${props.id}`;

    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed:5000,
        slidesToShow: 1,
        arrows: true,
        dots: false,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    };
    console.log(props)
    return (
        <div className={props.cols ? props.cols : 'col-md-6 col-lg-4'}>
            <div className="blog-item" style={{backgroundColor:'#ffc966'}}>
                {
                    props.thumb ? (
                        <figure className="blog-thumb">
                            <Link to={`${process.env.PUBLIC_URL + blogURL}`}>
                                <img src={require('../../assets/img/' + props.thumb)} alt={props.title} />
                            </Link>
                        </figure>
                    ) : null
                }
                <div className="blog-content">
                    <h2 className="h5" style={{textAlign:'center'}}>{props.title}</h2>
                    <p style={{textAlign:'center'}}>{props.excerpt}</p>

                    <div className={'slider-area'} >
                <SlickSlider settings={settings} style={{width:'370px', height:'493px'}}>

                        <div key={`01`}>
                            <div className="slider-item" style={{height:'493px',backgroundImage: `url(${require('../../assets/img/slider/ayudamos-voluntarios1.jpeg')})`}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    

                



                </SlickSlider>
            </div>

                </div>
            </div>
        </div>
    );
}

export default BlogItem;