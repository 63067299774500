import React from 'react';
import Feature from './FeatureItem'
import FeaturesData from '../../data/Features/features'

function Features({classes}) {
    return (
        <div className={`feature-area-wrapper ${classes}`} style={{paddingTop:'30px'}}>
            <div className="container"cstyle={{marginTop:'0px'}}>
                <div className="row mtn-sm-60 mtn-md-5">
 
                            <Feature />

                </div>
            </div>
        </div>
    );
}

export default Features;