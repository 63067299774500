import React from 'react';

function FeatureItem(props) {
    return (
        <>
        <div className="col-md-4">
            <div className="icon-box-item">
                <div className="icon-box__icon">
                    <i className={`fa fa-university fa-2x`} alt="Businex-Feature"></i>
                </div>
                <div className="icon-box__info">
                    <h5>Deducible de impuestos</h5>
                    <p>Cada peso donado es totalmente deducible de impuestos.</p>
                </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="icon-box-item">
                <div className="icon-box__icon">
                    <i className={`fa fa-bullseye fa-2x`} alt="Businex-Feature"></i>
                </div>
                <div className="icon-box__info">
                    <h5>Ahorros en tu empresa</h5>
                    <p>Si donas alimento, economizas en costos de almacenaje y traslado.</p>
                </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="icon-box-item">
                <div className="icon-box__icon">
                    <i className={`fa fa-lock fa-2x`} alt="Businex-Feature"></i>
                </div>
                <div className="icon-box__info">
                    <h5>Protege tu marca</h5>
                    <p>Evita que tu alimento llegue al mercado informal con con nosotros.</p>
                </div>
            </div>
        </div>
        </>

    );
}

export default FeatureItem;