import React from 'react';
import SectionTitle from "../UI/SectionTitle";
import BlogItem from "./blogItem";
import BlogItem2 from "./blogItem2";
import BlogItem3 from "./blogItem3";

import Blogs from '../../data/Blog/blog';


function Blog() {
    return (
        <div className="blog-area-wrapper sm-top">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                    <SectionTitle  title="" heading="Si tú, tu
comunidad o institución de asistencia social desean ser beneficiarios
del Banco de Alimentos Mérida y recibir quincenal o mensualmente
ayuda alimentaria consulta los requisitos y contáctanos."/>
                    <br/>
                    </div>
                </div>

                <div className="row mtn-35" style={{marginBottom:'90px'}}>
                    {
                        Blogs.slice(0,1).map(blog =>(
                            <BlogItem key={blog.id} id={blog.id} imgs={blog.imgs} title={blog.title} excerpt={blog.excerpt} postBy={blog.author.name} date={blog.publishDate}/>
                            
                        ))
                    }
                    {
                        Blogs.slice(1,2).map(blog =>(
                            <BlogItem2 key={blog.id} id={blog.id} imgs={blog.imgs} title={blog.title} excerpt={blog.excerpt} postBy={blog.author.name} date={blog.publishDate}/>
                            
                        ))
                    }
                    {
                        Blogs.slice(2,3).map(blog =>(
                            <BlogItem3 key={blog.id} id={blog.id} imgs={blog.imgs} title={blog.title} excerpt={blog.excerpt} postBy={blog.author.name} date={blog.publishDate}/>
                            
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Blog;